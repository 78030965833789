<template>
  <q-form
    @submit="onSubmit"
    class="q-gutter-md"
  >
    <q-input
      dense
      outlined
      no-error-icon
      v-model="credentials.password"

      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      :spellcheck="false"
      hide-bottom-space

      ref="password"
      placeholder="Nova Senha"
      :type="isPassword ? 'password' : 'text'"
      :rules="[
        () => $v.credentials.password.required || 'Senha é obrigatória',
        () => $v.credentials.password.minLength || 'Senha inválida'
      ]"
    >
      <template #prepend>
        <q-icon
          name="mdi-lock-reset"
          size="xs"
        />
      </template>

      <template #append>
        <q-icon
          size="xs"
          :name="isPassword ? 'mdi-eye-off' : 'mdi-eye'"
          class="cursor-pointer"
          @click="isPassword = !isPassword"
        />
      </template>
    </q-input>

    <q-input
      dense
      outlined
      no-error-icon

      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      :spellcheck="false"
      hide-bottom-space

      v-model="credentials.passwordConfirm"
      placeholder="Confirmar Senha"
      :type="isPassword ? 'password' : 'text'"
      :rules="[
        () => $v.credentials.passwordConfirm.sameAsPassword || 'Senha não confere'
      ]"
    >
      <template #prepend>
        <q-icon
          name="mdi-shield-check-outline"
          size="xs"
        />
      </template>

      <template #append>
        <q-icon
          size="xs"
          :name="isPassword ? 'mdi-eye-off' : 'mdi-eye'"
          class="cursor-pointer"
          @click="isPassword = !isPassword"
        />
      </template>
    </q-input>

    <div class="row items-center justify-between">
      <q-btn
        flat
        dense
        no-caps
        padding="0"
        :ripple="false"
        color="primary"
        label="Voltar"
        :to="authLoginRoute"
      />

      <AuthSubmit
        label="Definir senha"
        :loading="auth.loading"
      />
    </div>
  </q-form>
</template>

<script>
import { mapState } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { ROUTE_AUTH_SIGN_IN, ACTION_AUTH_DEFINE_PASSWORD } from '../constants'
import AuthSubmit from '../components/AuthSubmit'

export default {
  components: {
    AuthSubmit
  },

  data () {
    return {
      isPassword: true,
      credentials: {},
      authLoginRoute: { name: ROUTE_AUTH_SIGN_IN }
    }
  },

  mounted () {
    this.$nextTick().then(() => (
      this.$refs.password.focus()
    ))
  },

  computed: {
    ...mapState({
      auth: ({ authStore }) => authStore
    })
  },

  validations: {
    credentials: {
      password: { required, minLength: minLength(8) },
      passwordConfirm: { sameAsPassword: sameAs('password') }
    }
  },

  methods: {
    onSubmit () {
      if (this.auth.loading) return
      this.$store.dispatch(ACTION_AUTH_DEFINE_PASSWORD, this.credentials.password)
    }
  }
}
</script>
